import axios from "axios";
import qs from "qs";
import Vue from "vue";
import store from "../store";

export default () => {
  let Api = axios.create({
    baseURL: "https://api.notif.immo",
    headers: {
      PlatformOrigin: "teddi",
      "Content-Type": "application/ld+json",
    },
  });
  Api.interceptors.request.use(config => {
    store.commit("UPDATE_LOADING", true);
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.paramsSerializer = params => {
      return qs.stringify(params, {
        arrayFormat: "indices",
        encode: false,
        skipNulls: true,
        strictNullHandling: true,
      });
    };
    return config;
  });
  Api.interceptors.response.use(
    response => {
      store.commit("UPDATE_LOADING", false);
      return response;
    },
    error => {
      store.commit("UPDATE_LOADING", false);
      let stringData = JSON.stringify(error.response.data);
      let parsedData = JSON.parse(stringData);
      if (
        error.response.status &&
        error.response.status === 401 &&
        (parsedData.message == "Expired JWT Token" ||
          parsedData.message == "Invalid JWT Token" ||
          parsedData.message == "JWT Token not found")
      ) {
        store.dispatch("logout");
        Vue.notify({
          group: "global",
          title: "Votre session a expiré. Veuillez vous reconnecter pour accéder à votre compte.",
        });
      }
      if (
        error.response.status &&
        error.response.status === 401 &&
        parsedData.message == "Account disabled."
      )
        Vue.notify({
          group: "global",
          title: "Veuillez d'abord confirmer votre compte.",
        });
      if (error.response.status && error.response.status === 400) {
        Vue.notify({
          group: "global",
          title: error.response.data["hydra:description"],
        });
      }
      return Promise.reject(error);
    }
  );
  return Api;
};
