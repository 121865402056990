var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('notifications',{attrs:{"group":"global","position":"top right","animation-type":"css","animation-name":"slide","speed":500}}),_c('div',{attrs:{"id":"topbar"}},[(
        _vm.$route.name === 'landing' ||
          _vm.$route.name === 'login' ||
          _vm.$route.name === 'map-locations' ||
          _vm.$route.name === 'request-new-password' ||
          _vm.$route.name === 'reset-password-success' ||
          _vm.$route.name === 'terms' ||
          _vm.$route.name === 'privacy' ||
          _vm.$route.name === '404' ||
          _vm.$route.name === 'reset-password' ||
          _vm.$route.name === 'expired' ||
          _vm.$route.name === 'expired-token' ||
          _vm.$route.name === 'timeline-buy' ||
          _vm.$route.name === 'timeline-rent' ||
          _vm.$route.name === 'blog-home' ||
          _vm.$route.name === 'estimate' ||
          _vm.$route.path.includes('/blog/') ||
          _vm.$route.name === 'thanks-hunter' ||
          _vm.$route.name === 'thanks-refurbishment' ||
          _vm.$route.name === 'thanks-guarantor'
      )?_c('landing-nav'):_vm._e(),(_vm.$route.name === 'dashboard')?_c('dashboard-nav'):_vm._e(),(
        _vm.$route.name === 'register' ||
          _vm.$route.name === 'form-hunter' ||
          _vm.$route.name === 'form-refurbishment' ||
          _vm.$route.name === 'form-insurance' ||
          _vm.$route.name === 'form-guarantor'
      )?_c('register-nav'):_vm._e()],1),_c('div',{attrs:{"id":"sidebar"}},[_c('Sidebar')],1),_c('div',{attrs:{"id":"content"}},[_c('router-view')],1),_c('transition',{attrs:{"name":"fade","mode":"in-out"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.cookie && this.$router.currentRoute.name == 'landing'),expression:"!cookie && this.$router.currentRoute.name == 'landing'"}],staticClass:"p-3 px-md-5 py-md-3",attrs:{"id":"cookie"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 offset-md-1 mb-3 mb-md-0"},[_c('h6',{staticClass:"text-left mb-0"},[_vm._v(" 🍪 En poursuivant votre navigation, "),_c('strong',[_vm._v("vous acceptez l'utilisation de “cookies”")]),_vm._v(" destinés à améliorer la performance de ce site, à en adapter les fonctionnalités. ")])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-btn',{staticClass:"btn-blue mr-3",on:{"click":function($event){return _vm.setCookie('accepted')}}},[_vm._v("Accepter")]),_c('div',{staticClass:"text-bold text-blue cursor-pointer",on:{"click":function($event){return _vm.setCookie('refused')}}},[_vm._v(" Refuser ")])],1)])])])]),(_vm.loading)?_c('Spinner',{staticClass:"loading",attrs:{"size":55,"line-size":7,"line-fg-color":"#4285f4","speed":0.6}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }