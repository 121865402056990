import Vue from "vue";
import Vuex from "vuex";
import Api from "@/api/api";
import ApiNoBearer from "@/api/apiNoBearer";
import router from "@/router";
import { createStore } from "vuex-extensions";
Vue.use(Vuex);

const store = createStore(Vuex.Store, {
  state: {
    /***** SETTINGS *****/
    token: localStorage.getItem("token") || null,
    decodedToken: null,
    stripe: null,
    userId: null,
    stripeKey: null,
    retarget: false,
    clientSecret: null,
    skipCheckout: false,
    previsionCount: null,
    isEmailAvailable: false,
    confirmationToken: null,
    /***** USER DATA *****/
    userData: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      referralCoupon: null,
    },
    stopSearchReason: "FOUND_VIA_THE_PLATFORM",
    stopSearchComment: "",
    coupon: null,
    password: null,
    utm_campaign: null,
    utm_medium: null,
    utm_content: null,
    utm_source: null,
    oldPassword: null,
    optionalFields: {
      visitCount: null,
      searchStage: null,
      refurbishmentType: null,
      potentialSeller: null,
      withGuarantor: null,
      withLoan: null,
      propertyAddress: null,
      userAddress: null,
      floor: null,
      room: null,
      occupantType: null,
      propertyGoal: null,
      cancelPreviousPolicy: null,
      professionalStatus: null,
      monthlyRevenues: null,
      savings: null,
      responsibleParty: null,
      id: null,
      proofOfSavings: null,
      proofOfRevenues: null,
      schoolCertificate: null,
      responsiblePartyEmail: null,
      responsiblePartyMonthlyRevenues: null,
      responsiblePartySavings: null,
      responsiblePartyID: null,
      responsiblePartyProofOfRevenues: null,
      responsiblePartyProofOfSavings: null,
    },
    /***** USER SEARCH *****/
    userSearch: {
      transactionType: null,
      propertyTypes: [],
      includedCities: [],
      includedDepartments: [],
      budgetMax: null,
      surfaceMin: null,
      bedroomMin: undefined,
      pricePerMeterMax: null,
      pricePerMeterMin: null,
      budgetMin: null,
      surfceMax: null,
      notificationEnabled: true,
    },
    /***** SUBSCRIPTION *****/
    dbSubscriptionObject: {
      currentPeriodEnd: "",
      plan: "",
      status: "",
      inProgress: false,
      isStripeFinalized: false,
      isFree: false,
      createdAt: "",
      updatedAt: "",
      isValid: false,
    },
    stripeUserObject: {},
    bills: null,
    /***** AD *****/
    currentDisplayAd: null,
    /***** COMPONENTS STATE *****/
    isFirstStepView: true,
    isSecondStepView: false,
    isPhoneValid: false,
    hasInputBeenChanged: false,
    isComponentLoaded: false,
    pointedLocation: "Paris",
    isPropertyTypeOpened: false,
    isLocationOpened: false,
    isBedroomMinOpened: false,
    guarantorData: null,
    isStripeElementOpened: false,
    isSearchLoaded: false,
    isUserLoaded: false,
    isNavOpen: false,
    isSearchInputOpened: false,
    isSearchSavable: false,
    isUserSavable: false,
    haveFiltersBeenUpdated: false,
    welcomeMsgConfirmed: false,
    loading: false,
  },
  mutations: {
    /***** SETTINGS *****/
    SET_DISPLAY_AD(state, ad) {
      state.currentDisplayAd = ad;
    },
    SET_STRIPE(state, value) {
      state.stripe = value;
    },
    SET_SKIP_CHECKOUT(state, value) {
      state.skipCheckout = value;
    },
    SET_EMAIL_AVAILABILITY(state, value) {
      state.isEmailAvailable = value;
    },
    SET_DECODED_JWT_TOKEN(state, value) {
      state.decodedToken = value;
    },
    SET_UTM_CAMPAIGN(state, value) {
      state.utm_campaign = value;
    },
    SET_UTM_CONTENT(state, value) {
      state.utm_content = value;
    },
    SET_UTM_SOURCE(state, value) {
      state.utm_source = value;
    },
    SET_UTM_MEDIUM(state, value) {
      state.utm_medium = value;
    },
    SET_RETARGET(state, value) {
      state.retarget = value;
    },
    RETRIEVE_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER_ID(state, value) {
      state.userId = value;
    },
    SET_CONFIRMATION_TOKEN(state, value) {
      state.confirmationToken = value;
    },
    SET_STRIPE_KEY(state, value) {
      state.stripeKey = value;
    },
    SET_CLIENT_SECRET(state, value) {
      state.clientSecret = value;
    },
    SET_PASSWORD(state, value) {
      state.password = value;
    },
    SET_OLD_PASSWORD(state, value) {
      state.oldPassword = value;
    },
    /***** REGISTER *****/
    SET_EMAIL(state, value) {
      state.userData.email = value;
    },
    SET_FIRST_NAME(state, value) {
      state.userData.firstName = value;
    },
    SET_LAST_NAME(state, value) {
      state.userData.lastName = value;
    },
    SET_COUPON(state, value) {
      state.coupon = value;
    },
    SET_PHONE(state, value) {
      state.userData.phone = value;
    },
    SET_IS_PHONE_VALID(state, value) {
      state.isPhoneValid = value;
    },
    SET_IS_PROPERTY_TYPE_OPENED(state, value) {
      state.isPropertyTypeOpened = value;
    },
    SET_IS_LOCATION_OPENED(state, value) {
      state.isLocationOpened = value;
    },
    SET_IS_BEDROOM_MIN_OPENED(state, value) {
      state.isBedroomMinOpened = value;
    },
    SET_USER_SEARCH_WATCHER(state, value) {
      state.userSearchWatcher = value;
    },
    SET_TRANSACTION_TYPE(state, value) {
      state.userSearch.transactionType = value;
    },
    SET_PROPERTY_TYPE(state, values) {
      state.userSearch.propertyTypes = values;
    },
    SET_INCLUDED_LOCATIONS(state, { cities, departments }) {
      state.userSearch.includedCities = cities;
      state.userSearch.includedDepartments = departments;
    },
    SET_BUDGET_MIN(state, value) {
      state.userSearch.budgetMin = value;
    },
    SET_BUDGET_MAX(state, value) {
      state.userSearch.budgetMax = value;
    },
    SET_PRICE_PER_METER_MIN(state, value) {
      state.userSearch.pricePerMeterMin = value;
    },
    SET_PRICE_PER_METER_MAX(state, value) {
      state.userSearch.pricePerMeterMax = value;
    },
    SET_SURFACE_MIN(state, value) {
      state.userSearch.surfaceMin = value;
    },
    SET_SURFACE_MAX(state, value) {
      state.userSearch.surfaceMax = value;
    },
    SET_BEDROOMS(state, value) {
      state.userSearch.bedroomMin = value;
    },
    SET_PREVISION_COUNT(state, value) {
      state.previsionCount = value;
    },
    SET_IS_COMPONENT_LOADED(state, value) {
      state.isComponentLoaded = value;
    },
    SET_FIRST_STEP_VIEW(state, value) {
      state.isFirstStepView = value;
    },
    SET_SECOND_STEP_VIEW(state, value) {
      state.isSecondStepView = value;
    },
    /***** OPTIONAL FIELDS *****/
    SET_VISIT_COUNT(state, value) {
      state.optionalFields.visitCount = value;
    },
    SET_SEARCH_STAGE(state, value) {
      state.optionalFields.searchStage = value;
    },
    SET_REFURBISHMENT_TYPE(state, value) {
      state.optionalFields.refurbishmentType = value;
    },
    SET_PROPERTY_ADDRESS(state, value) {
      state.optionalFields.propertyAddress = value;
    },
    SET_USER_ADDRESS(state, value) {
      state.optionalFields.userAddress = value;
    },
    SET_FLOOR(state, value) {
      state.optionalFields.floor = value;
    },
    SET_ROOM(state, value) {
      state.optionalFields.room = value;
    },
    SET_OCCUPANT_TYPE(state, value) {
      state.optionalFields.occupantType = value;
    },
    SET_CANCEL_PREVIOUS_POLICY(state, value) {
      state.optionalFields.cancelPreviousPolicy = value;
    },
    SET_PROPERTY_GOAL(state, value) {
      state.optionalFields.propertyGoal = value;
    },
    SET_PROFESSIONAL_STATUS(state, value) {
      state.optionalFields.professionalStatus = value;
    },
    SET_MONTHLY_REVENUES(state, value) {
      state.optionalFields.monthlyRevenues = value;
    },
    SET_SAVINGS(state, value) {
      state.optionalFields.savings = value;
    },
    SET_RESPONSIBLE_PARTY(state, value) {
      state.optionalFields.responsibleParty = value;
    },
    SET_RESPONSIBLE_PARTY_EMAIL(state, value) {
      state.optionalFields.responsiblePartyEmail = value;
    },
    SET_RESPONSIBLE_PARTY_MONTHLY_REVENUES(state, value) {
      state.optionalFields.responsiblePartyMonthlyRevenues = value;
    },
    SET_RESPONSIBLE_PARTY_SAVINGS(state, value) {
      state.optionalFields.responsiblePartySavings = value;
    },
    SET_ID(state, file) {
      state.optionalFields.id = file;
    },
    SET_PROOF_OF_REVENUES(state, file) {
      state.optionalFields.proofOfRevenues = file;
    },
    SET_PROOF_OF_SAVINGS(state, file) {
      state.optionalFields.proofOfSavings = file;
    },
    SET_RESPONSIBLE_PARTY_ID(state, file) {
      state.optionalFields.responsiblePartyID = file;
    },
    SET_RESPONSIBLE_PARTY_PROOF_OF_SAVINGS(state, file) {
      state.optionalFields.responsiblePartyProofOfSavings = file;
    },
    SET_RESPONSIBLE_PARTY_PROOF_OF_REVENUES(state, file) {
      state.optionalFields.responsiblePartyProofOfRevenues = file;
    },
    SET_SCHOOL_CERTIFICATE(state, file) {
      state.optionalFields.schoolCertificate = file;
    },
    /***** DASHBOARD *****/
    SET_SEARCH(state, value) {
      state.userSearch = value;
    },
    SET_CONFIRM_FORM_WELCOME_MSG(state, value) {
      state.welcomeMsgConfirmed = value;
    },
    SET_HAVE_FILTERS_BEEN_UPDATED(state, value) {
      state.haveFiltersBeenUpdated = value;
    },
    SET_HAS_INPUT_BEEN_CHANGED(state, value) {
      state.hasInputBeenChanged = value;
    },
    SET_IS_SEARCH_SAVABLE(state, value) {
      state.isSearchSavable = value;
    },
    SET_IS_USER_SAVABLE(state, value) {
      state.isUserSavable = value;
    },
    UPDATE_LOADING(state, value) {
      state.loading = value;
    },
    SET_IS_SEARCH_LOADED(state, value) {
      state.isSearchLoaded = value;
    },
    SET_IS_USER_LOADED(state, value) {
      state.isUserLoaded = value;
    },
    TOGGLE_NAV(state) {
      state.isNavOpen = !state.isNavOpen;
    },
    CLOSE_NAV(state) {
      state.isNavOpen = false;
    },
    SET_GUARANTOR_DATA(state, value) {
      state.guarantorData = value;
    },
    SET_NOTIFICATION_ENABLED(state, value) {
      state.userSearch.notificationEnabled = value;
    },
    SET_STOP_SEARCH_REASON(state, value) {
      state.stopSearchReason = value;
    },
    SET_STOP_SEARCH_COMMENT(state, value) {
      state.stopSearchComment = value;
    },
    /***** SETTINGS *****/
    SET_DB_SUBSCRIPTION_OBJECT(state, object) {
      state.dbSubscriptionObject = object;
    },
    SET_STRIPE_USER_OBJECT(state, object) {
      state.stripeUserObject = object;
    },
    SET_BILLS(state, array) {
      state.bills = array;
    },
    SET_USER_DATA(state, value) {
      state.userData = value;
    },
  },
  actions: {
    /*--------REGISTRATION/LOGIN--------*/
    /*--Check if email already exists--*/
    async checkEmailAvailability({ state }) {
      ApiNoBearer()
        .get("/public/check-email-availability?email=" + encodeURIComponent(state.userData.email))
        .then(res => {
          if (res.data) {
            this.commit("SET_EMAIL_AVAILABILITY", res.data.success);
          }
        });
    },
    /*--Stripe JS--*/
    async setStripe({ commit }) {
      return ApiNoBearer()
        .get("/public/settings")
        .then(res => {
          if (res.data.STRIPE_PUB_KEY) {
            commit("SET_STRIPE_KEY", res.data.STRIPE_PUB_KEY);
            commit("SET_STRIPE", window.Stripe(res.data.STRIPE_PUB_KEY));
          }
        });
    },
    /*--Form--*/
    async registerUser({ commit, state, dispatch, getters }) {
      ApiNoBearer()
        .post("/public/registration-with-search", {
          "email": state.userData.email,
          "password": state.password,
          "utmSource": state.utm_source,
          "utmMedium": state.utm_medium,
          "utmCampaign": state.utm_campaign,
          "utmContent": state.utm_content,
          "couponCode": state.coupon,
          "potentialSeller": state.optionalFields.potentialSeller,
          "withLoan": state.optionalFields.withLoan,
          "withGuarantor": state.optionalFields.withGuarantor,
          "bedroomMin": state.userSearch.bedroomMin,
          "transactionType": state.userSearch.transactionType,
          "propertyTypes": state.userSearch.propertyTypes,
          "budgetMin": null,
          "budgetMax": state.userSearch.budgetMax,
          "surfaceMin": state.userSearch.surfaceMin,
          "surfaceMax": null,
          "pricePerMeterMin": null,
          "pricePerMeterMax": null,
          "includedCities": getters.getIRISFromIncludedCities,
          "excludedCities": null,
          "includedDepartments": getters.getIRISFromIncludedDepartments,
        })
        .then(res => {
          commit("SET_USER_ID", res.data["@id"]);
        })
        .then(() => {
          dispatch("stripeRegister");
          state.password = "";
        });
    },
    /*--Stripe--*/
    async stripeRegister({ state }) {
      let successUrl = window.location.origin + "/thanks" + "?email=" + state.userData.email;
      let successUrlRetarget = window.location.origin + "/thanks-retarget";
      ApiNoBearer()
        .post("/public/payment/create-checkout-session", {
          user: state.userId,
          successUrl: state.retarget == true ? successUrlRetarget : successUrl,
          cancelUrl: window.location.origin,
          plan: "teddi_one_search_plan",
          retarget: state.retarget,
          skipCheckout: state.skipCheckout,
        })
        .then(res => {
          if (!res.data.stripeSessionCheckoutID && state.skipCheckout === true) {
            router.push({ path: "/thanks" + "?email=" + state.userData.email });
          } else {
            state.stripe.redirectToCheckout({
              sessionId: res.data.stripeSessionCheckoutID,
            });
          }
        });
    },
    /*--Confirm User from Confirmation Email--*/
    async confirmAccount({ commit }, confirmationToken) {
      ApiNoBearer()
        .post("/public/security/confirmation", {
          confirmationToken: confirmationToken,
        })
        .then(res => {
          const token = res.data.token;
          localStorage.setItem("token", token);
          commit("RETRIEVE_TOKEN", token);
        });
    },
    /*--Login--*/
    async retrieveToken({ commit, state }) {
      ApiNoBearer()
        .post("/auth", {
          email: state.userData.email,
          password: state.password,
        })
        .then(res => {
          const token = res.data.token;
          localStorage.setItem("token", token);
          commit("RETRIEVE_TOKEN", token);
        })
        .then(() => {
          router.push({ name: "dashboard" });
        })
        .then(() => {
          state.password = "";
        });
    },
    /*--SUBSCRIPTIONS--*/
    /*--Fetch User Data--*/
    async fetchUserData({ commit }) {
      Api()
        .get("/account/user")
        .then(res => {
          commit("SET_USER_DATA", res.data);
          commit("SET_USER_ID", res.data["@id"]);
        })
        .then(() => {
          commit("SET_IS_USER_LOADED", true);
        });
    },
    /*--Fetch User dB Subscription--*/
    async fetchSubscription({ commit }) {
      return Api()
        .get("/account/subscription")
        .then(res => {
          commit("SET_DB_SUBSCRIPTION_OBJECT", res.data.subscription);
        });
    },
    /*--Fetch User dB Subscription + Stripe Subscription--*/
    async fetchStripeAndSubscription({ commit, state }) {
      Api()
        .get("/account/subscription?withStripe=true")
        .then(res => {
          if (res.data.stripe) {
            commit("SET_STRIPE_USER_OBJECT", res.data.stripe);
          }
          if (res.data.subscription) {
            commit("SET_DB_SUBSCRIPTION_OBJECT", res.data.subscription);
          }
        })
        .then(() => {
          state.isStripeElementOpened = false;
        });
    },
    /*--Switch Subscription to Other Plan or Unsubscribed Plan--*/
    async switchSubscription({ dispatch, state }, value) {
      Api()
        .post("/account/switch-subscription", {
          "toPlan": value,
          "reason": state.stopSearchReason,
          "comment": state.stopSearchComment,
        })
        .then(res => {
          if (res.data.success) {
            setTimeout(() => {
              dispatch("fetchStripeAndSubscription");
              Vue.notify({
                group: "global",
                title: "Votre demande a bien été prise en compte",
              });
            }, 2000);
          }
        });
    },
    /*--STRIPE--*/
    /*--Create Stripe Setup Intent--*/
    async createSetupIntent({ commit }) {
      ApiNoBearer()
        .get("/public/payment/create-setup-intent")
        .then(res => {
          commit("SET_CLIENT_SECRET", res.data.client_secret);
        });
    },
    /*--Confirm Stripe Setup Intent--*/
    async confirmSetupIntent({ dispatch }, setupIntent) {
      Api()
        .post("/payment/confirm-setup-intent", {
          setupIntent: setupIntent,
        })
        .then(() => {
          dispatch("fetchStripeAndSubscription");
        })
        .then(() => {
          Vue.notify({
            group: "global",
            title: "Votre moyen de paiement a bien été mis à jour",
          });
        });
    },
    /*--Add Card in Resume Subscription--*/
    async confirmSetupIntentPublic({}, { setupIntent, UUID }) {
      ApiNoBearer()
        .post("/public/payment/confirm-setup-intent", {
          setupIntent: setupIntent,
          user: UUID,
          plan: "teddi_one_search_plan",
        })
        .then(() => {
          router.push({ name: "email-confirmed" });
        })
        .then(() => {
          Vue.notify({
            group: "global",
            title: "Votre moyen de paiement a bien été mis à jour",
          });
        });
    },
    /*--------SEARCHES--------*/
    /*--Fetch Searches--*/
    async fetchSearch({ commit }) {
      return Api()
        .get("/searches")
        .then(res => {
          if (res.data["hydra:member"] && res.data["hydra:member"].length > 0)
            commit("SET_SEARCH", res.data["hydra:member"][0]);
        })
        .then(() => {
          commit("SET_IS_SEARCH_LOADED", true);
        });
    },
    /*--Save Existing Search--*/
    async saveSearch({ commit, getters }) {
      Api()
        .put(getters.getCurrentSearchId, getters.currentSearchCleansedForPUT)
        .then(res => {
          if (!res.data["/contexts/Error"]) {
            commit("SET_SEARCH", res.data);
            Vue.notify({
              group: "global",
              title: "Votre recherche a bien été sauvegardée",
            });
          }
        })
        .then(() => {
          commit("SET_IS_SEARCH_SAVABLE", false);
          commit("SET_HAS_INPUT_BEEN_CHANGED", false);
        });
    },
    /*--Disable Notifications--*/
    async disableNotifications({ state, dispatch, commit }) {
      return ApiNoBearer()
        .post("/public/search/disable-notifications", {
          "searchToken": state.userSearch.token,
          "reason": state.stopSearchReason,
          "comment": state.stopSearchComment,
        })
        .then(res => {
          if (res.data) {
            Vue.notify({
              group: "global",
              title: "Votre demande a bien été prise en compte",
            });
          }
        });
    },
    /*--Fetch Prevision Count--*/
    async fetchPrevisionCount({ commit, getters }) {
      ApiNoBearer()
        .get("/public/prevision-count", {
          params: getters.currentSearchCleansedForPUT,
        })
        .then(res => {
          if (res) commit("SET_PREVISION_COUNT", res.data);
        });
    },
    /*--Refresh Vue Currency Inputs--*/
    async refreshVueCurrencyInputs({ commit }) {
      commit("SET_IS_COMPONENT_LOADED", false);
      setTimeout(() => {
        commit("SET_IS_COMPONENT_LOADED", true);
      }, 1000);
    },
    /*--------ADS--------*/
    /*--Fetch Ad to Display to User from his Match--*/
    async fetchAdDisplay({ commit }, token) {
      ApiNoBearer()
        .get("/public/match?token=" + token)
        .then(res => {
          if (res.data.uuid) commit("SET_DISPLAY_AD", res.data);
          else router.push({ name: "expired" });
        });
    },
    /*--------SETTINGS--------*/
    /*--Update Password--*/
    async updatePassword({ commit, state }) {
      Api()
        .post("/account/change_password", {
          oldPassword: state.oldPassword,
          password: state.password,
        })
        .then(() => {
          commit("SET_PASSWORD", null);
          commit("SET_OLD_PASSWORD", null);
          Vue.notify({
            group: "global",
            title: "Votre mot de passe a bien été mis à jour",
          });
        });
    },
    /*--Request New Password--*/
    async forgotPasswordRequest({ state }) {
      ApiNoBearer()
        .post("/public/security/forgot-password/request", {
          email: state.userData.email,
        })
        .then(() => {
          router.push({ path: "/thanks" + "?email=" + state.userData.email });
        });
    },
    /*--Set New Password--*/
    async forgotPasswordReset({ state }, token) {
      ApiNoBearer()
        .post("/public/security/forgot-password/reset", {
          password: state.password,
          resetToken: token,
        })
        .then(() => {
          router.push({ name: "reset-password-success" });
        });
    },

    /*--Fetch all Bills--*/
    async fetchBills({ commit }) {
      Api()
        .get("/account/invoices")
        .then(res => {
          commit("SET_BILLS", res.data);
        });
    },
    /*--Update Phone--*/
    async updatePhone({ state, commit }) {
      Api()
        .put(state.userData["@id"], {
          phone: state.userData.phone,
        })
        .then(res => {
          if (res.data)
            Vue.notify({
              group: "global",
              title: "Votre numéro de téléphone a bien été enregistré",
            });
          commit("SET_IS_USER_SAVABLE", false);
        });
    },
    /*--Download JSON Data--*/
    async fetchAndDownloadUserData() {
      Api()
        .get("/export-data")
        .then(res => {
          var json = JSON.stringify(res.data);
          json = [json];
          var blob1 = new Blob(json, { type: "text/plain;charset=utf-8" });
          //Check the Browser.
          var isIE = false || !!document.documentMode;
          if (isIE) {
            window.navigator.msSaveBlob(blob1, "data.json");
          } else {
            let url = window.URL || window.webkitURL;
            let link = url.createObjectURL(blob1);
            var a = document.createElement("a");
            a.download = "data.json";
            a.href = link;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        });
    },
    /*--Logout--*/
    logout({ commit }) {
      router.push({ name: "login" });
      this.reset();
      localStorage.removeItem("token");
      commit("RETRIEVE_TOKEN", null);
    },
    /*--Anonymize Account--*/
    async anonymizeAccount({ dispatch, state }) {
      Api()
        .put("/anonymization-demand", {
          "reason": state.stopSearchReason,
          "comment": state.stopSearchComment,
        })
        .then(res => {
          if (res.data) {
            dispatch("logout");
            Vue.notify({
              group: "global",
              title: "Votre compte a bien été supprimé",
            });
          }
        });
    },
    /*--------PARTNERS--------*/
    /*--Insurance--*/
    async sendToPartnerInsurance({ state, getters, commit }) {
      commit("UPDATE_LOADING", true);
      fetch("https://x.notif.immo/" + "https://flow.notif.immo/webhook/1/webhook/insurance", {
        method: "POST",
        body: JSON.stringify({
          "created_at": Vue.moment().format("YYYY-MM-DD"),
          "address": state.optionalFields.propertyAddress.properties.label,
          "residence_type": getters.getLukoPropertyType,
          "size": state.userSearch.surfaceMin,
          "floor": state.optionalFields.floor ? getters.getLukoFloor : "ground",
          "room": state.optionalFields.room,
          "occupanttype": getters.getLukoOccupantType,
          "firstname": state.userData.firstName,
          "lastname": state.userData.lastName,
          "email": state.userData.email,
          "postcode": state.optionalFields.propertyAddress.properties.postcode,
          "city": state.optionalFields.propertyAddress.properties.city,
          "country": "France",
          "main_home": true,
          "had_policy": true,
          "had_policy_start_date": "1997-02-28",
          "capital_amount": 21000,
          "partner_user_id": "4242ABCD",
          "source": "teddi",
          "owner_address": getters.getOwnerAddress,
          "owner_postcode": getters.getOwnerZipcode,
          "owner_city": getters.getOwnerCity,
          "owner_country": "France",
          "address_complement": "",
          "floor_number": 2,
          "chimney": false,
          "fire_alarm": false,
          "theft_alarm": false,
          "solar_panels": false,
          "veranda": false,
          "outbuilding_size": "small",
          "swimmingpool": false,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data) {
            if (
              data[0].MINLEG &&
              (data[0].MINLEG.url.includes("/mrh") || data[0].MINLEG.url.includes("/hu")) &&
              data[0].MINLEG.url_pricing != ""
            )
              window.location.replace(data[0].MINLEG.url_pricing);
            if (
              data[0].DEFAULT &&
              data[0].DEFAULT.url.includes("/pno") &&
              data[0].DEFAULT.url_pricing != ""
            )
              window.location.replace(data[0].DEFAULT.url_pricing);
          } else {
            router.push({ name: "landing" });
            commit("UPDATE_LOADING", false);
            Vue.notify({
              group: "global",
              title: "Votre demande a été prise en compte",
            });
          }
        });
    },
    /*--Hunter--*/
    async sendToPartnerHunter({ state, getters }) {
      fetch("https://x.notif.immo/" + "https://flow.notif.immo/webhook/6/webhook/hunter", {
        method: "POST",
        body: JSON.stringify({
          "created_at": Vue.moment().format("YYYY-MM-DD"),
          "budgetMax": getters.getBudgetMaxThousandSeparator,
          "visitCount": state.optionalFields.visitCount,
          "locations": getters.getSelectedLocations,
          "surfaceMin": state.userSearch.surfaceMin,
          "firstName": state.userData.firstName,
          "lastName": state.userData.lastName,
          "email": state.userData.email,
          "phone": state.userData.phone,
          "source": getters.isHunterJRDM && !getters.isHunterMCM ? "teddi1" : "teddi2",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }).then(response => {
        if (response) router.push({ name: "thanks-hunter" });
      });
    },
    /*--Refurbishment--*/
    async sendToPartnerRefurbishment({ state }) {
      fetch("https://x.notif.immo/" + "https://flow.notif.immo/webhook/5/webhook/refurbishment", {
        method: "POST",
        body: JSON.stringify({
          "created_at": Vue.moment().format("YYYY-MM-DD"),
          "title": state.optionalFields.searchStage,
          "description":
            state.optionalFields.refurbishmentType +
            " - " +
            state.userSearch.budgetMax +
            "€ - " +
            state.userSearch.surfaceMin +
            "m²",
          "address": state.optionalFields.propertyAddress.properties.label,
          "firstName": state.userData.firstName,
          "lastName": state.userData.lastName,
          "phoneNumber": state.userData.phone,
          "email": state.userData.email,
          "source": "teddi",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }).then(response => {
        if (response) router.push({ name: "thanks-refurbishment" });
      });
    },
    /*--Bank--*/
    async sendToPartnerBank({ state }, utm_medium) {
      fetch("https://x.notif.immo/" + "https://flow.notif.immo/webhook/7/webhook/bank", {
        method: "POST",
        body: JSON.stringify({
          "created_at": Vue.moment().format("YYYY-MM-DD"),
          "utm_source": "teddi",
          "utm_medium": utm_medium,
          "utm_campaign": "misapret",
          "utm_content": "",
          "email": state.userData.email,
          "is_subscribed": "false",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }).then(response => {
        if (response)
          window.open(
            "https://www.misapret.com?utm_source=teddi&utm_medium=timeline&utm_campaign=misapret&utm_content=" +
              state.userData.email
          );
      });
    },
    /*--Guarantor--*/
    async sendToPartnerGuarantor({ state }) {
      fetch("https://x.notif.immo/" + "https://flow.notif.immo/webhook/8/webhook/guarantor-json", {
        method: "POST",
        body: JSON.stringify({
          "rent_budget_cents": state.userSearch.budgetMax ? state.userSearch.budgetMax * 100 : 1,
          "tenant": {
            "savings_cents": state.optionalFields.savings ? state.optionalFields.savings * 100 : 1,
            "monthly_revenues_cents": state.optionalFields.monthlyRevenues
              ? state.optionalFields.monthlyRevenues * 100
              : 1,
            "professional_status_id": state.optionalFields.professionalStatus,
            "profile": {
              "email": state.userData.email,
            },
          },
          "responsible_party": {
            "savings_cents": state.optionalFields.responsiblePartySavings
              ? state.optionalFields.responsiblePartySavings * 100
              : 1,
            "monthly_revenues_cents": state.optionalFields.responsiblePartyMonthlyRevenues
              ? state.optionalFields.responsiblePartyMonthlyRevenues * 100
              : 1,
            "profile": {
              "email": state.optionalFields.responsiblePartyEmail
                ? state.optionalFields.responsiblePartyEmail
                : "noResponsibleParty@teddi.co",
            },
          },
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }).then(response => {
        if (response) router.push({ name: "thanks-guarantor" });
      });
    },
  },
  getters: {
    /***** GENERAL *****/
    isNavOpen: state => {
      return state.isNavOpen;
    },
    isLoggedIn: state => {
      return state.token != null;
    },
    /***** REGISTER *****/
    isTransactionTypeSet: state => {
      return state.userSearch.transactionType != null;
    },
    isPropertyTypeSet: state => {
      return state.userSearch.propertyTypes.length > 0;
    },
    isLocationSet: state => {
      return (
        state.userSearch.includedCities.length > 0 ||
        state.userSearch.includedDepartments.length > 0
      );
    },
    isBudgetMinSet: state => {
      return state.userSearch.budgetMin != null;
    },
    isBudgetMaxSet: state => {
      return state.userSearch.budgetMax != null;
    },
    isPricePerMeterMinSet: state => {
      return state.userSearch.pricePerMeterMin != null;
    },
    isPricePerMeterMaxSet: state => {
      return state.userSearch.pricePerMeterMax != null;
    },
    isSurfaceMinSet: state => {
      return state.userSearch.surfaceMin != null;
    },
    isSurfaceMaxSet: state => {
      return state.userSearch.surfaceMax != null;
    },
    isBedroomMinSet: state => {
      return state.userSearch.bedroomMin !== undefined;
    },
    isEmailSet: state => {
      return state.userData.email != null && state.userData.email != "";
    },
    isPasswordSet: state => {
      return state.password != null && state.password.length > 5;
    },
    isFirstNameSet: state => {
      return state.userData.firstName != null;
    },
    isLastNameSet: state => {
      return state.userData.lastName != null;
    },
    isPhoneSet: state => {
      return state.userData.phone != null;
    },
    isPromoSet: state => {
      return state.promoCode != null;
    },
    isPropertyResidential: state => {
      return (
        state.userSearch.propertyTypes.includes(0) || state.userSearch.propertyTypes.includes(1)
      );
    },
    /***** OPTIONAL FIELDS *****/
    isVisitCountSet: state => {
      return state.optionalFields.visitCount != null;
    },
    isSearchStageSet: state => {
      return state.optionalFields.searchStage != null;
    },
    isRefurbishmentTypeSet: state => {
      return state.optionalFields.refurbishmentType != null;
    },
    isPropertyAddressSet: state => {
      return state.optionalFields.propertyAddress != null;
    },
    isUserAddressSet: state => {
      if (state.optionalFields.occupantType != "propriétaire") return true;
      if (
        state.optionalFields.occupantType == "propriétaire" &&
        state.optionalFields.propertyGoal == "ma résidence principale"
      )
        return true;
      if (state.optionalFields.occupantType == "propriétaire")
        return state.optionalFields.userAddress != null;
    },
    isFloorSet: state => {
      return state.optionalFields.floor != null;
    },
    isRoomSet: state => {
      return state.optionalFields.room > 0 && state.optionalFields.room < 11;
    },
    isOccupantTypeSet: state => {
      return state.optionalFields.occupantType != null;
    },
    isPropertyGoalSet: state => {
      return state.optionalFields.propertyGoal != null;
    },
    isCancelPreviousPolicySet: state => {
      return state.optionalFields.cancelPreviousPolicy != null;
    },
    isProfessionalStatusSet: state => {
      return state.optionalFields.professionalStatus != null;
    },
    getLukoPropertyType: state => {
      if (state.userSearch.propertyTypes.length && state.userSearch.propertyTypes == 0)
        return "flat";
      if (state.userSearch.propertyTypes.length && state.userSearch.propertyTypes == 1)
        return "house";
    },
    getLukoFloor: state => {
      if (state.optionalFields.floor && state.optionalFields.floor == "au dernier étage")
        return "top";
      if (state.optionalFields.floor && state.optionalFields.floor == "à un étage intermédiaire")
        return "middle";
      if (state.optionalFields.floor && state.optionalFields.floor == "au rez-de-chaussée")
        return "ground";
    },
    getLukoOccupantType: state => {
      if (state.optionalFields.occupantType && state.optionalFields.occupantType == "locataire")
        return "tenant";
      if (state.optionalFields.occupantType && state.optionalFields.occupantType == "propriétaire")
        return "owner";
    },
    getOwnerAddress: state => {
      if (state.optionalFields.userAddress) {
        return state.optionalFields.userAddress.properties.label;
      } else {
        return "";
      }
    },
    getOwnerZipcode: state => {
      if (state.optionalFields.userAddress) {
        return state.optionalFields.userAddress.properties.postcode;
      } else {
        return "";
      }
    },
    getOwnerCity: state => {
      if (state.optionalFields.userAddress) {
        return state.optionalFields.userAddress.properties.city;
      } else {
        return "";
      }
    },
    /***** DASHBOARD *****/
    getFirstSelectedLocation: state => {
      if (state.userSearch.includedCities.length) return state.userSearch.includedCities[0].name;
      if (state.userSearch.includedDepartments.length)
        return state.userSearch.includedDepartments[0].name;
    },
    getSelectedLocations: state => {
      let locations = [];
      state.userSearch.includedCities.forEach(city => {
        locations.push(city.name);
      });
      state.userSearch.includedDepartments.forEach(department => {
        locations.push(department.name);
      });
      return locations;
    },
    isBigCity: state => {
      if (state.userSearch.includedCities.length)
        return (
          state.userSearch.includedCities[0].name.includes("Paris") ||
          state.userSearch.includedCities[0].name.includes("Lyon") ||
          state.userSearch.includedCities[0].name.includes("Marseille")
        );
      if (state.userSearch.includedDepartments.length)
        return state.userSearch.includedDepartments[0].name.includes("Paris");
    },
    checkZipcodes: state => {
      let zipcodes = [
        "92100",
        "92110",
        "92120",
        "92130",
        "92150",
        "92170",
        "92200",
        "92240",
        "92250",
        "92270",
        "92300",
        "92400",
        "92500",
        "92600",
        "92800",
        "94130",
        "94160",
        "94170",
        "94200",
        "94210",
        "94220",
        "94270",
        "94300",
        "94700",
        "94800",
      ];
      let booleans = [];
      for (const zip of zipcodes) {
        booleans.push(state.userSearch.includedCities.some(city => city.zipcode == zip));
      }
      return booleans.includes(true);
    },
    isHunterJRDM: (state, getters) => {
      return (
        state.userSearch.includedCities.some(city => city.name.includes("Paris")) ||
        getters.checkZipcodes ||
        state.userSearch.includedDepartments.some(dept => dept.name.includes("Paris"))
      );
    },
    isHunterMCM: state => {
      let cityNames = [
        "Lyon",
        "Marseille",
        "Montpellier",
        "Antibes",
        "Toulouse",
        "Bordeaux",
        "Nantes",
        "Rennes",
        "Angers",
        "Lille",
        "Nice",
        "Cannes",
        "Toulon",
        "Aix-en-provence",
      ];
      let booleans = [];
      for (const cityName of cityNames) {
        booleans.push(state.userSearch.includedCities.some(city => city.name.includes(cityName)));
      }
      return booleans.includes(true);
    },
    /***** SUBSCRIPTION *****/
    isSubscriptionValid: state => {
      return state.dbSubscriptionObject.isValid == true;
    },
    isStripeFinalized: state => {
      return state.dbSubscriptionObject.isStripeFinalized;
    },
    isSubscriptionTrialing: state => {
      return state.dbSubscriptionObject.status == "trialing";
    },
    isSubscriptionActive: state => {
      return state.dbSubscriptionObject.status == "active";
    },
    isDbSubscriptionLoaded: state => {
      return state.dbSubscriptionObject.createdAt !== "";
    },
    getSubscriptionPlan: state => {
      return state.dbSubscriptionObject.plan;
    },
    isUnsubscribedPlan: state => {
      return state.dbSubscriptionObject.plan == "teddi_unsubscribed_plan";
    },
    isBasicPlan: state => {
      return (
        state.dbSubscriptionObject.plan == "teddi_one_search_plan" ||
        state.dbSubscriptionObject.plan == "fetcher_plan_premium" ||
        state.dbSubscriptionObject.plan == "teddi_free_search_plan"
      );
    },

    /***** STRIPE CARD *****/
    isStripeUserObjectLoaded: state => {
      return Object.keys(state.stripeUserObject).length > 0;
    },
    getLast4: state => {
      return state.stripeUserObject.card.last4;
    },
    doesCardExist: state => {
      return state.stripeUserObject.card != null;
    },
    isCardMastercard: state => {
      return state.stripeUserObject.card.brand == "mastercard";
    },
    isCardVisa: state => {
      return state.stripeUserObject.card.brand == "visa";
    },
    getExpirationDate: state => {
      return state.stripeUserObject.card.exp_month + " / " + state.stripeUserObject.card.exp_year;
    },
    /***** SEARCH *****/
    getCurrentSearchId: state => {
      return state.userSearch["@id"];
    },
    countAdResults: state => {
      return state.ads.length;
    },
    getCurrentPeriodEnd: state => {
      return Vue.moment
        .unix(state.stripeUserObject.subscription.current_period_end)
        .format("dddd DD MMMM YYYY" + " à " + "HH:mm");
    },
    getThirtyDaysAgo() {
      return Vue.moment()
        .subtract(720, "hours")
        .format("YYYY-MM-DD");
    },
    getSevenDaysAgo() {
      return Vue.moment()
        .subtract(168, "hours")
        .format("YYYY-MM-DD");
    },
    getFortyEightHoursAgo() {
      return Vue.moment()
        .subtract(48, "hours")
        .format("YYYY-MM-DD");
    },
    getTwentyFourHoursAgo() {
      return Vue.moment()
        .subtract(24, "hours")
        .format("YYYY-MM-DD");
    },
    getNow() {
      return Vue.moment().format("YYYY-MM-DD");
    },
    getBudgetMaxThousandSeparator: state => {
      let number = state.userSearch.budgetMax.toString().split(".");
      number[0] = number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return number.join(".");
    },

    getIRISFromIncludedCities: state => {
      return state.userSearch.includedCities.map(i => i["@id"]);
    },
    getIRISFromIncludedDepartments: state => {
      return state.userSearch.includedDepartments.map(i => i["@id"]);
    },
    currentSearchCleansedForPUT: (state, getters) => {
      const currentSearch = state.userSearch;
      const { includedCities, includedDepartments, ...cleansedCurrentSearch } = currentSearch;
      cleansedCurrentSearch.includedCities = getters.getIRISFromIncludedCities;
      cleansedCurrentSearch.includedDepartments = getters.getIRISFromIncludedDepartments;
      return cleansedCurrentSearch;
    },
    formatDate: (state) => (date) => {
      return Vue.moment(date).fromNow()
    },
    thousandSeparator: (state) => (amount) => {
      if (
        amount !== "" ||
        amount !== undefined ||
        amount !== 0 ||
        amount !== "0" ||
        amount !== null
      ) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return amount;
      }

    },
    cleansedEvents: (state, getters) => {
      let events = state.currentDisplayAd.adverts.map(advert => advert.events).flat()
      let uniqueEvents = [...new Map(events.map(event => [event['fieldNewValue'] + event[
        'fieldName'], event])).values()]
        let intEvents = uniqueEvents.filter(el => el.percentVariation) 

      if (intEvents && intEvents.length) {
        let timeline = intEvents.map(obj => {
          let tmp = {}
          if (obj.fieldName == 'surface') {
            tmp.icon = require('../assets/icons/area-rounded.svg')
            tmp.description = obj.fieldOldValue + ' m² → ' + obj.fieldNewValue + ' m²'
          }
          if (obj.fieldName == 'price') {
            tmp.icon = require('../assets/icons/price-rounded.svg')
            tmp.description = getters.thousandSeparator(obj.fieldOldValue) + ' € → ' +
              getters.thousandSeparator(obj.fieldNewValue) + ' €'
          }

          let properties = {
            icon: tmp.icon,
            title: obj.percentVariation > 0 ? '+' + obj.percentVariation + ' %' : obj
              .percentVariation + ' %',
            description: tmp.description,
            date: new Date(obj.createdAt),
          };
          return properties;
        });
        return timeline
      }
    },
    cleansedContacts: (state) => {
      let contacts = state.currentDisplayAd.adverts.map(obj => {
        let tmp = obj.contact
        tmp.publisherType = obj.publisher.type
        return tmp
      })
      let noContact = contacts.filter(contact => contact.email || contact.phone || contact
        .agency || contact.name)
      let withContact = contacts.filter(contact => contact.email || contact.phone)
      let uniqueNames = [...new Map(withContact.map(contact => [contact['agency'], contact])).values()]
      let uniqueEmails = [...new Map(uniqueNames.map(contact => [contact['email'], contact])).values()]
      let uniquesContact = [...new Map(uniqueEmails.map(contact => [contact['phone'], contact])).values()]
      let uniquesNoContact = [...new Map(noContact.map(contact => [contact['name'] + contact[
        'agency'], contact])).values()]
      if (uniquesContact && uniquesContact.length) {
        return uniquesContact
      } else {
        return uniquesNoContact
      }
    },
    getUrl: (state) => {
      // get adverts with urls that are not null
      let disclosedAdverts = state.currentDisplayAd.adverts.filter(advert => advert.url)
      // get adverts that are not expired
      let activeAdverts = disclosedAdverts.filter(advert => advert.expired === false)
      // if there are some => get the most recent crawled advert
      if (activeAdverts && activeAdverts.length) {
        return activeAdverts.sort((a,b) => new Date(b.lastCrawledAt).getTime() - new Date(a.lastCrawledAt).getTime())[0].url;
      } else {        
         // if not get the most recent crawled advert overall
        return disclosedAdverts && disclosedAdverts.length ? disclosedAdverts.sort((a,b) => new Date(b.lastCrawledAt).getTime() - new Date(a.lastCrawledAt).getTime())[0].url : null
      }     
    },
  },
});

export default store;
