<template>
  <div class="container-fluid px-0">
    <div class="row-fluid bg-blue py-2 justify-content-center">
      <b-img class="logo mb-0" alt="teddi-logo" :src="require('@/assets/logo/teddi-white.svg')">
      </b-img>
    </div>
  </div>
</template>

<script>
export default {};
</script>

