<template>
  <div class="container-fluid px-0 ">
    <div class="bg-blue px-3 px-md-4 py-2 d-flex align-items-center justify-content-between">
      <router-link
        class="logo mb-0"
        to="/dashboard"
        tag="img"
        alt="teddi-logo"
        :src="require('@/assets/logo/teddi-white.svg')"
      >
      </router-link>
      <div class="my-auto burger">
        <Burger />
      </div>
    </div>
  </div>
</template>

<script>
import Burger from "@/components/topbar/Burger.vue";

export default {
  components: {
    Burger
  }
};
</script>

<style lang="scss" scoped>
.logo {
  cursor: pointer;
}
</style>
