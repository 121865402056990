<template>
  <div class="sidebar">
    <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
    <transition name="slide-left">
      <div v-if="isPanelOpen" class="sidebar-panel d-flex align-items-center flex-column">
        <b-img alt="teddi" class="logo" :src="require('@/assets/logo/teddi-white.svg')"></b-img>
        <ul class="sidebar-panel-nav px-0 w-100">
          <li v-if="this.$store.state.token != null">
            <IconSearch class="icon-sm-2 fill-white" />
            <b-nav-item @click="closeNav" :to="{ name: 'dashboard' }">Ma recherche</b-nav-item>
          </li>
          <li v-if="this.$store.state.token == null">
            <IconSearch class="icon-sm-2 fill-white" />
            <b-nav-item @click="closeNav" :to="{ name: 'login' }">Se connecter</b-nav-item>
          </li>
          <li v-if="this.$store.state.userSearch.transactionType != null">
            <IconStars class="icon-sm-2 fill-white" />
            <b-nav-item
              v-if="this.$store.state.userSearch.transactionType == 0"
              :to="{ name: 'timeline-buy' }"
              >Services</b-nav-item
            >
            <b-nav-item
              v-if="this.$store.state.userSearch.transactionType == 1"
              :to="{ name: 'timeline-rent' }"
              >Services</b-nav-item
            >
          </li>
          <li v-if="this.$store.state.userSearch.transactionType == null">
            <IconStars class="icon-sm-2 fill-white" />
            <b-nav-item :to="{ name: 'timeline-rent' }">Services location</b-nav-item>
          </li>
          <li v-if="this.$store.state.userSearch.transactionType == null">
            <IconStars class="icon-sm-2 fill-white" />
            <b-nav-item :to="{ name: 'timeline-buy' }">Services achat</b-nav-item>
          </li>
          <li v-if="this.$store.state.token != null">
            <IconUser class="icon-sm-2 fill-white" />
            <b-nav-item :to="{ name: 'settings' }">Mon compte</b-nav-item>
          </li>
        </ul>

        <div class="d-flex align-items-center mt-auto">
          <b-nav-item @click="logout" v-b-tooltip.hover title="Se déconnecter">
            <b-img
              alt="power"
              class="content-icon"
              :src="require('@/assets/icons/power-off.svg')"
            ></b-img>
          </b-nav-item>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import IconStars from "@/components/icons/IconStars.vue";
import IconSearch from "@/components/icons/IconSearch.vue";
import IconUser from "@/components/icons/IconUser.vue";
export default {
  components: {
    IconStars,
    IconSearch,
    IconUser,
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapMutations({
      closeSidebarPanel: "TOGGLE_NAV",
    }),
    closeNav() {
      this.$store.commit("CLOSE_NAV");
    },
  },
  computed: {
    ...mapGetters({
      isPanelOpen: "isNavOpen",
      isLoggedIn: "isLoggedIn",
    }),
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/tooltips.scss";
a,
a:hover {
  color: white;
}
ul {
  li {
    display: flex;
    align-items: center;
  }
}
ul > li {
  padding-left: 4.3rem;
  &:hover {
    background-color: $light-blue;
  }
}
.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 998;
}

.sidebar-panel {
  color: white;
  overflow-y: auto;
  background-color: $primary;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  padding-top: 1rem;
  width: 300px;
  font-size: 1.3rem;
  line-height: 3rem;
}
.logo {
  margin-bottom: 2rem;
}
</style>
