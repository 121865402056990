import Vue from "vue";
import App from "./App.vue";
import VueGtm from "vue-gtm";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";
import smoothscroll from 'smoothscroll-polyfill';
import VueCurrencyInput from "vue-currency-input";
import { BootstrapVue } from "bootstrap-vue";
import Vue2Filters from "vue2-filters";
import Notifications from "vue-notification";
import { MazPhoneNumberInput } from "maz-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VTimelinePro from 'vue-timeline-pro';

Vue.use(VTimelinePro);

smoothscroll.polyfill();

var Vue2FiltersConfig = {
  number: {
    format: "0,0",
    thousandsSeparator: " ",
    decimalSeparator: ".",
  },
};
const moment = require("moment");
require("moment/locale/fr");

Vue.use(require("vue-moment"), {
  moment,
});
Vue.use(MazPhoneNumberInput);
Vue.use(Notifications);
Vue.use(BootstrapVue);
Vue.use(VueCurrencyInput);
Vue.use(Vue2Filters, Vue2FiltersConfig);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueGtm, {
  id: "GTM-WVH28L6",
  enabled: true,
  vueRouter: router,
  ignoredViews: [
    "dashboard",
    "expired",
    "settings",
    "expired-token",
    "reset-password",
    "request-new-password",
    "reset-password-success",
    "404",
    "display",
  ],
});

Vue.config.productionTip = false;
Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0);

new Vue({
  router,
  store,
  mixins: [Vue2Filters.mixin],
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");
