<template>
  <div id="app">
    <!--  <div class="bandeau py-3 text-center bg-blue text-white w-100">Mise à jour en cours - Veuillez nous excuser pour la gêne occasionnée.</div> -->
    <notifications
      group="global"
      position="top right"
      animation-type="css"
      animation-name="slide"
      :speed="500"
    />
    <div id="topbar">
      <landing-nav
        v-if="
          $route.name === 'landing' ||
            $route.name === 'login' ||
            $route.name === 'map-locations' ||
            $route.name === 'request-new-password' ||
            $route.name === 'reset-password-success' ||
            $route.name === 'terms' ||
            $route.name === 'privacy' ||
            $route.name === '404' ||
            $route.name === 'reset-password' ||
            $route.name === 'expired' ||
            $route.name === 'expired-token' ||
            $route.name === 'timeline-buy' ||
            $route.name === 'timeline-rent' ||
            $route.name === 'blog-home' ||
            $route.name === 'estimate' ||
            $route.path.includes('/blog/') ||
            $route.name === 'thanks-hunter' ||
            $route.name === 'thanks-refurbishment' ||
            $route.name === 'thanks-guarantor'
        "
      />
      <dashboard-nav v-if="$route.name === 'dashboard'" />
      <register-nav
        v-if="
          $route.name === 'register' ||
            $route.name === 'form-hunter' ||
            $route.name === 'form-refurbishment' ||
            $route.name === 'form-insurance' ||
            $route.name === 'form-guarantor'
        "
      />
    </div>
    <div id="sidebar">
      <Sidebar />
    </div>
    <div id="content">
      <router-view />
    </div>
    <transition name="fade" mode="in-out">
      <div id="cookie" v-show="!cookie && this.$router.currentRoute.name == 'landing'" class="p-3 px-md-5 py-md-3">
        <div class="row">
          <div class="col-md-8 offset-md-1 mb-3 mb-md-0">
            <h6 class="text-left mb-0">
              🍪 En poursuivant votre navigation,
              <strong>vous acceptez l'utilisation de “cookies”</strong> destinés à améliorer la
              performance de ce site, à en adapter les fonctionnalités.
            </h6>
          </div>
          <div class="col-md-2">
            <div class="d-flex align-items-center">
              <b-btn class="btn-blue mr-3" @click="setCookie('accepted')">Accepter</b-btn>
              <div class="text-bold text-blue cursor-pointer" @click="setCookie('refused')">
                Refuser
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <Spinner
      class="loading"
      v-if="loading"
      :size="55"
      :line-size="7"
      line-fg-color="#4285f4"
      :speed="0.6"
    ></Spinner>
  </div>
</template>

<script>
import RegisterNav from "@/components/topbar/RegisterNav.vue";
import DashboardNav from "@/components/topbar/DashboardNav.vue";
import LandingNav from "@/components/topbar/LandingNav.vue";
import Sidebar from "@/components/sidebar/Sidebar.vue";
import Spinner from "vue-simple-spinner";
import { mapState } from "vuex";
import Vue from "vue";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

Sentry.init({
  dsn: "https://37334afcfbb24dd58c6ca5d9f79cc31f@o402313.ingest.sentry.io/5263273",
  environment: "prod",
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
});

export default {
  components: {
    RegisterNav,
    DashboardNav,
    Sidebar,
    LandingNav,
    Spinner,
  },
  data() {
    return {
      cookie: null,
    };
  },
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    setCookie(value) {
      localStorage.setItem("cookie:consent", value);
      this.cookie = value;
    },
  },
  mounted() {
    let params = new URLSearchParams(window.location.search);
    this.$store.commit("SET_UTM_CAMPAIGN", params.get("utm_campaign"));
    this.$store.commit("SET_UTM_MEDIUM", params.get("utm_medium"));
    this.$store.commit("SET_UTM_CONTENT", params.get("utm_content"));
    this.$store.commit("SET_UTM_SOURCE", params.get("utm_source"));
    this.cookie = localStorage.getItem("cookie:consent");
  },
};
</script>

<style lang="scss">
@import "@/styles/fonts.scss";
@import "@/styles/transitions.scss";
#app {
  font-family: "Inter", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $body-text;
  overflow-x: hidden !important;
  max-width: 100% !important;
}

.vue-notification {
  background: white !important;
  border-left: none !important;
  box-shadow: $box-shadow;
  margin-top: 0.5rem !important;
  border-radius: $radius;
  padding: 1.5rem !important;
  .notification-title {
    text-align: left;
    color: $primary;
    font-size: 1rem;
  }
}
.loading {
  position: fixed;
  z-index: 998;
  height: 5rem;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  &:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
  svg path {
    fill: transparent;
  }
}
</style>
<style lang="scss" scoped>
@import "@/styles/buttons.scss";

#cookie {
  position: fixed;
  bottom: 0;
  background: #f4f4f4;
  box-shadow: 0 0 12px 0 rgba(48, 41, 41, 0.15);
  right: 0;
  left: 0;
  z-index: 1500;
  font-size: 1rem;
}
</style>
