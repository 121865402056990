import Vue from "vue";
import VueRouter from "vue-router";
import store from "./../store";
import BlogPosts from "./../assets/blog/posts.json";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName:"landing"*/ "./../views/Landing.vue"),
    name: "landing",
    /*  meta: {
      redirectToDashboardIfLoggedIn: true,
    }, */
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName:"dashboard"*/ "./../views/Dashboard.vue"),
    async beforeEnter(to, from, next) {
      await store.dispatch("fetchSubscription").then(() => {
        if (store.getters.isSubscriptionValid == false) {
          Vue.notify({
            group: "global",
            title: "Veuillez d'abord choisir un abonnement pour accéder à votre recherche",
          });
          next({
            name: "settings",
          });
        } else {
          next();
        }
      });
    },
  },
  {
    path: "/register/",
    name: "register",
    component: () => import(/* webpackChunkName:"register"*/ "./../views/Register.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import(/* webpackChunkName:"settings"*/ "./../views/Settings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "*",
    component: () => import(/* webpackChunkName:"404"*/ "./../views/404.vue"),
    name: "404",
  },
  {
    path: "/expired",
    component: () => import(/* webpackChunkName:"expired"*/ "./../views/ExpiredAd.vue"),
    name: "expired",
  },
  {
    path: "/expired-token",
    component: () => import(/* webpackChunkName:"expired-token"*/ "./../views/ExpiredToken.vue"),
    name: "expired-token",
  },
  {
    path: "/login/",
    component: () => import(/* webpackChunkName:"login"*/ "./../views/Login.vue"),
    name: "login",
  },
  {
    path: "/request-new-password/",
    component: () =>
      import(/* webpackChunkName:"request-new-password"*/ "./../views/RequestNewPassword.vue"),
    name: "request-new-password",
  },
  {
    path: "/reset-password/:token",
    component: () => import(/* webpackChunkName:"reset-password"*/ "./../views/ResetPassword.vue"),

    name: "reset-password",
  },
  {
    path: "/thanks",
    component: () => import(/* webpackChunkName:"thanks-signup"*/ "./../views/ThanksSignup.vue"),
    name: "thanks-signup",
  },
  {
    path: "/thanks-retarget",
    component: () =>
      import(/* webpackChunkName:"thanks-retarget"*/ "./../views/ThanksRetarget.vue"),
    name: "thanks-retarget",
  },
  {
    path: "/reset-password-success",
    component: () =>
      import(/* webpackChunkName:"reset-success"*/ "./../views/ResetPasswordSuccess.vue"),
    name: "reset-password-success",
  },
  {
    path: "/confirmation/:confirmationToken",
    component: () => import(/* webpackChunkName:"thanks-confirm"*/ "./../views/EmailConfirmed.vue"),
    name: "email-confirmed",
  },
  {
    path: "/resume/skipped-checkout/:uuid",
    component: () =>
      import(
        /* webpackChunkName:"resume-skipped-checkout"*/ "./../views/ResumeSkippedCheckout.vue"
      ),
    name: "resume-skipped-checkout",
  },
  {
    path: "/resume/checkout/:uuid",
    component: () =>
      import(/* webpackChunkName:"resume-checkout"*/ "./../views/ResumeCheckout.vue"),
    name: "resume-checkout",
  },
  {
    path: "/ad-verification/:token",
    component: () => import(/* webpackChunkName:"display2"*/ "./../views/AdDisplay.vue"),
    name: "display2",
  },
  {
    path: "/display/:token",
    component: () => import(/* webpackChunkName:"display"*/ "./../views/AdDisplay.vue"),
    name: "display",
  },
  {
    path: "/terms/",
    component: () => import(/* webpackChunkName:"terms"*/ "./../views/Terms.vue"),
    name: "terms",
  },
  {
    path: "/privacy/",
    component: () => import(/* webpackChunkName:"privacy"*/ "./../views/Privacy.vue"),
    name: "privacy",
  },
  {
    path: "/etapes-achat-immobilier/",
    component: () => import(/* webpackChunkName:"timeline-buy"*/ "./../views/TimelineBuy.vue"),
    name: "timeline-buy",
  },
  {
    path: "/etapes-location-immobilier/",
    component: () => import(/* webpackChunkName:"timeline-rent"*/ "./../views/TimelineRent.vue"),
    name: "timeline-rent",
  },
  {
    path: "/departements/",
    component: () => import(/* webpackChunkName:"map-locations"*/ "./../views/MapLocations.vue"),
    name: "map-locations",
  },
  {
    path: "/chasseur/",
    component: () => import(/* webpackChunkName:"form-hunter"*/ "./../views/FormHunter.vue"),
    name: "form-hunter",
  },
  {
    path: "/thanks-chasseur",
    component: () =>
      import(/* webpackChunkName:"thanks-hunter"*/ "./../views/ThanksFormHunter.vue"),
    name: "thanks-hunter",
  },
  {
    path: "/travaux/",
    component: () =>
      import(/* webpackChunkName:"form-refurbishment"*/ "./../views/FormRefurbishment.vue"),
    name: "form-refurbishment",
  },
  {
    path: "/thanks-travaux",
    component: () =>
      import(/* webpackChunkName:"thanks-refurbishment"*/ "./../views/ThanksFormRefurbishment.vue"),
    name: "thanks-refurbishment",
  },
  {
    path: "/assurance/",
    component: () => import(/* webpackChunkName:"form-insurance"*/ "./../views/FormInsurance.vue"),
    name: "form-insurance",
  },
  {
    path: "/garant/",
    component: () => import(/* webpackChunkName:"form-guarantor"*/ "./../views/FormGuarantor.vue"),
    name: "form-guarantor",
  },
  {
    path: "/thanks-garant",
    component: () =>
      import(/* webpackChunkName:"thanks-guarantor"*/ "./../views/ThanksFormGuarantor.vue"),
    name: "thanks-guarantor",
  },

  {
    path: "/estimate",
    component: () => import(/* webpackChunkName:"estimate"*/ "./../views/Estimate.vue"),
    name: "estimate",
  },
  {
    path: "/blog/",
    component: () => import(/* webpackChunkName:"blog-home"*/ "./../views/BlogHome.vue"),
    name: "blog-home",
  },
  {
    path: "/blog/:id/",
    component: () => import(/* webpackChunkName:"blog-post"*/ "./../views/BlogPost.vue"),
    name: "blog-post",
    children: BlogPosts.map(post => ({
      path: `/blog/${post.id}`,
      name: post.id,
      meta: {
        title: post.title,
        description: post.description,
        img: post.img,
        date: post.date,
      },
      component: () =>
        import(/* webpackChunkName:"[request]"*/ `./../components/blog/${post.id}.md`),
    })),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  store.commit("UPDATE_LOADING", false);
  store.commit("CLOSE_NAV");
});

router.beforeEach((to, from, next) => {
  store.commit("UPDATE_LOADING", true);
  /* if (to.matched.some(record => record.meta.redirectToDashboardIfLoggedIn)) {
    if (store.getters.isLoggedIn) {
      next({
        name: "dashboard",
      });
    } else {
      next();
    }
  } else {
    next();
  } */
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.token == null) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
