import axios from "axios";
import Vue from "vue";
import store from "../store";
import router from "@/router";

export default () => {
  let ApiNoBearer = axios.create({
    baseURL: "https://api.notif.immo",
    headers: {
      "PlatformOrigin": "teddi",
      "Content-Type": "application/ld+json",
    },
  });
  ApiNoBearer.interceptors.request.use(config => {
    if (!config.url.includes("location_autocomplete")) store.commit("UPDATE_LOADING", true);
    return config;
  });
  ApiNoBearer.interceptors.response.use(
    response => {
      store.commit("UPDATE_LOADING", false);
      return response;
    },
    error => {
      store.commit("UPDATE_LOADING", false);
      if (
        error.response.status &&
        (error.response.status === 400 || error.response.status === 401)
      ) {
        /*--------Invalid credentials--------*/
        if (
          error.response.data.message &&
          error.response.data.message.message.includes("credentials")
        ) {
          Vue.notify({
            group: "global",
            title: "🚨 Identifiants incorrects",
          });
        }
        /*--------Account disabled--------*/
        if (
          error.response.data.message &&
          error.response.data.message.message.includes("Account disabled")
        ) {
          Vue.notify({
            group: "global",
            title:
              "Veuillez d'abord confirmer votre compte en cliquant dans l'email envoyé après votre inscription.",
          });
        }
        /*--------Confirmation token expired--------*/
        if (
          error.response &&
          error.response.data["hydra:description"].includes(
            "User not found with this confirmationToken"
          )
        ) {
          router.push({ name: "expired-token" });
        }
        /*--------Email already exists--------*/
        if (
          error.response &&
          error.response.data["hydra:description"].includes("Cet email est déjà utilisé")
        ) {
          Vue.notify({
            group: "global",
            title: "🚨 Cet email est déjà utilisé.",
          });
          store.commit("SET_EMAIL_AVAILABILITY", false);
        }
        /*--------Email not authorized--------*/
        if (
          error.response &&
          error.response.data["hydra:description"].includes(
            "Ce type d'adresse email n'est pas autorisé"
          )
        ) {
          Vue.notify({
            group: "global",
            title: "🚨 Ce type d'adresse email n'est pas autorisé",
          });
          store.commit("SET_EMAIL_AVAILABILITY", false);
        }
      }
      return Promise.reject(error);
    }
  );
  return ApiNoBearer;
};
