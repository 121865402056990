<template>
  <div class="container px-0">
    <!-- MOBILE -->
    <div class="bg-blue px-3 py-2 d-flex d-md-none align-items-center justify-content-between">
      <router-link
        class="logo mb-0"
        to="/"
        tag="img"
        alt="teddi-logo"
        :src="require('@/assets/logo/teddi-white.svg')"
      />
      <div class="my-auto burger">
        <Burger />
      </div>
    </div>
    <!-- DESKTOP -->
    <div class="py-2 d-none d-md-flex align-items-center justify-content-between">
      <router-link
        class="logo mb-0"
        to="/"
        tag="img"
        alt="teddi-logo"
        :src="require('@/assets/logo/teddi-blue.svg')"
      />
      <div class="d-md-flex">
        <b-btn v-if="isLoggedIn" to="/dashboard" class="px-3 py-2 my-3">Mon compte</b-btn>
        <b-btn v-else to="/login" class="btn btn-primary px-3 py-2 my-3">Se connecter</b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Burger from "@/components/topbar/Burger.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Burger,
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/buttons.scss";
.logo {
  cursor: pointer;
}
</style>
