<template>
  <svg
    version="1.1"
    id="Layer_1"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512;"
    xml:space="preserve"
  >
    <g id="oi1Tuj.tif">
      <g>
        <path
          class="st0"
          d="M290.7,493.4c15.7,0,24.5-12.2,22.7-24.5c-5.2-28-8.7-57.7-15.7-85.7c-1.7-7,0-12.2,5.2-17.5
			c21-19.2,40.2-38.5,61.2-59.5c7-7,10.5-14,7-24.5s-10.5-14-21-15.7c-28-3.5-56-8.7-85.7-12.2c-5.2,0-8.7-3.5-12.2-8.7
			c-12.2-26.2-26.2-52.5-38.5-78.7c-3.5-8.7-10.5-14-19.2-14c-10.5,0-15.7,5.2-21,14c-12.2,26.2-26.2,52.5-38.5,78.7
			c-1.7,3.5-5.2,7-8.7,7c-28,3.5-57.7,8.7-85.7,12.2c-10.5,1.7-17.5,5.2-21,15.7s1.7,17.5,8.7,24.5c19.2,19.2,38.5,38.5,59.5,57.7
			c7,5.2,7,12.2,5.2,19.2c-5.2,26.2-8.7,54.2-14,82.2c-1.7,10.5,0,17.5,8.7,24.5c8.7,5.2,15.7,5.2,24.5,0c26.2-14,50.7-28,77-40.2
			c3.5-1.7,8.7-1.7,10.5,0c26.2,14,52.5,26.2,77,40.2C283.7,489.9,288.9,491.7,290.7,493.4z M329.2,17.5c-3.5,3.5-5.2,7-7,8.7
			c-5.2,12.2-8.7,24.5-15.7,35c-1.7,3.5-5.2,8.7-10.5,10.5c-12.2,5.2-24.5,10.5-36.7,15.7c-1.7,1.7-5.2,3.5-5.2,5.2s3.5,5.2,5.2,7
			c12.2,5.2,22.7,10.5,35,14c5.2,1.7,8.7,5.2,12.2,12.2c3.5,12.2,8.7,22.7,14,33.2c1.7,3.5,5.2,5.2,7,7c1.7-1.7,5.2-3.5,7-7
			c5.2-12.2,8.7-22.7,14-35c1.7-3.5,5.2-8.7,10.5-10.5c12.2-5.2,22.7-10.5,35-15.7c1.7-1.7,5.2-3.5,5.2-7c0-1.7-3.5-5.2-5.2-7
			c-12.2-5.2-22.7-10.5-35-14c-5.2-1.7-8.7-5.2-10.5-12.2c-5.2-12.2-8.7-22.7-14-35C334.4,24.5,332.7,22.7,329.2,17.5z M434.2,286.9
			c1.7-3.5,3.5-5.2,5.2-7c3.5-8.7,7-17.5,10.5-26.2c1.7-3.5,7-8.7,10.5-10.5c8.7-5.2,17.5-7,26.2-10.5c1.7-1.7,5.2-3.5,5.2-5.2
			c0-1.7-3.5-3.5-5.2-5.2c-8.7-3.5-19.2-7-28-12.2c-3.5-1.7-7-5.2-8.7-8.7c-5.2-8.7-7-19.2-12.2-28c-1.7-1.7-3.5-5.2-5.2-5.2
			c-1.7,0-3.5,3.5-5.2,5.2c-1.7,3.5-3.5,7-3.5,10.5c-3.5,17.5-15.7,29.7-33.2,33.2c-3.5,0-7,1.7-10.5,5.2c-1.7,1.7-5.2,3.5-5.2,5.2
			c0,1.7,3.5,5.2,5.2,5.2c8.7,3.5,19.2,7,28,12.2c3.5,1.7,5.2,3.5,7,7c5.2,8.7,8.7,19.2,12.2,28
			C428.9,281.7,430.7,283.4,434.2,286.9z"
        />
        <path
          class="st0"
          d="M290.7,493.4c-1.7,0-7-1.7-10.5-3.5c-26.2-14-52.5-28-77-40.2c-3.5-1.7-8.7-1.7-10.5,0
			c-26.2,14-52.5,26.2-77,40.2c-8.7,5.2-17.5,7-24.5,0c-10.5-7-12.2-15.7-10.5-26.2c5.2-28,8.7-54.2,14-82.2c1.7-8.7,0-14-5.2-19.2
			c-21-19.2-40.2-38.5-59.5-57.7c-7-7-12.2-14-8.7-24.5c3.5-10.5,10.5-14,21-15.7c28-3.5,57.7-8.7,85.7-12.2c5.2,0,7-3.5,8.7-7
			c12.2-26.2,26.2-52.5,38.5-78.7c3.5-8.7,10.5-14,21-14s15.7,5.2,19.2,14c12.2,26.2,26.2,52.5,38.5,78.7c1.7,5.2,5.2,7,12.2,8.7
			c28,3.5,56,8.7,85.7,12.2c10.5,1.7,17.5,5.2,21,15.7c3.5,10.5,0,17.5-7,24.5c-21,19.2-40.2,40.2-61.2,59.5
			c-5.2,5.2-7,10.5-5.2,17.5c5.2,28,10.5,57.7,15.7,85.7C316.9,481.2,306.4,493.4,290.7,493.4z"
        />
        <path
          class="st0"
          d="M329.2,17.5c3.5,3.5,5.2,5.2,7,8.7c5.2,12.2,10.5,22.7,14,35c1.7,5.2,5.2,8.7,10.5,12.2
			c12.2,5.2,22.7,8.7,35,14c1.7,1.7,5.2,3.5,5.2,7c0,1.7-3.5,5.2-5.2,7c-12.2,5.2-24.5,10.5-35,15.7c-3.5,1.7-8.7,7-10.5,10.5
			c-5.2,10.5-10.5,22.7-14,35c-1.7,3.5-5.2,5.2-7,7c-1.7-1.7-5.2-5.2-7-7c-5.2-10.5-10.5-22.7-14-33.2c-1.7-5.2-5.2-8.7-12.2-12.2
			c-12.2-5.2-22.7-8.7-35-14c-1.7-1.7-5.2-3.5-5.2-7c0-1.7,3.5-5.2,5.2-5.2c12.2-5.2,24.5-10.5,36.7-15.7c5.2-5.2,8.7-8.7,10.5-12.2
			c5.2-12.2,10.5-22.7,15.7-35C323.9,24.5,327.4,22.7,329.2,17.5z"
        />
        <path
          class="st0"
          d="M434.2,286.9c-1.7-3.5-5.2-5.2-5.2-7c-3.5-8.7-7-19.2-12.2-28c-1.7-3.5-3.5-5.2-7-7
			c-8.7-5.2-19.2-8.7-28-12.2c-1.7-1.7-5.2-3.5-5.2-5.2c0-1.7,3.5-3.5,5.2-5.2c3.5-1.7,7-3.5,10.5-5.2c17.5-3.5,29.7-15.7,33.2-33.2
			c0-3.5,1.7-7,3.5-10.5c1.7-1.7,3.5-5.2,5.2-5.2c1.7,0,3.5,3.5,5.2,5.2c3.5,8.7,7,19.2,12.2,28c1.7,3.5,5.2,7,8.7,8.7
			c8.7,5.2,19.2,7,28,12.2c1.7,1.7,5.2,3.5,5.2,5.2c0,1.7-3.5,5.2-5.2,5.2c-8.7,3.5-17.5,7-26.2,10.5c-3.5,1.7-8.7,5.2-10.5,10.5
			c-5.2,8.7-7,17.5-10.5,26.2C437.7,281.7,435.9,283.4,434.2,286.9z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
svg {
  transform: scale(1.3);
  margin-left: 0.1rem;
  margin-bottom: 0.1rem;
}
</style>
