<template>
  <svg
    version="1.1"
    id="Layer_1"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      id="searchicon"
      class="st0"
      d="M485.9,428.4l-92.8-92.8c-4-4-10.1-6.1-16.1-6.1h-16.1c64.6-84.7,50.4-205.6-34.3-270.2
	S125,6.9,58.5,91.6S8.1,297.3,92.8,361.8c70.5,54.4,167.4,54.4,237.9,0V378c0,6.1,2,12,6.1,16.1l92.8,92.8c8.1,8.1,22.2,8.1,32.3,0
	l0,0l26.2-26.2C496,450.5,496,436.3,485.9,428.4z M211.8,329.5c-66.6,0-119-52.4-119-119s52.4-119,119-119s119,52.4,119,119
	C330.7,275.2,276.2,329.5,211.8,329.5L211.8,329.5z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
